import { useCallback, useContext, useState } from "react";
import Context from "../context/userContext";
import { Login, Logout, CandidatosGet, PreguntasGet } from "../service/login";
import Auth from "../utils/ValidateToken";

export default function useLogin() {
  const { cookies, setCookie, removeCookie } = useContext(Context);
  const [Candidatos, setCandidatos] = useState([]);
  const [PreguntasRespuestas, setPreguntasRespuestas] = useState([]);
  const [Cerrar, setCerrar] = useState("");
  const [state, setstate] = useState({
    loading: false,
    error: false,
    message: "",
    success: false
  });

  const login = useCallback(
    ({ email, password }) => {
      setstate({ loading: true, error: false, message: "" });

      Login({ email, password })
        .then((response) => {
          if (response.success) {
            setstate({ loading: false, error: false, message: "" });
            const token = Auth.encode(response);
            setCookie("jwt", token, { sameSite: true });
          } else {
            setstate({
              loading: false,
              error: true,
              message: response.message,
            });
            removeCookie("jwt");
          }
        })
        .catch((err) => {
          setstate({ loading: false, error: true, message: err });
          removeCookie("jwt");
        });
    },
    [removeCookie, setCookie]
  );

  const CandidatosGetService = useCallback((token) => {
    CandidatosGet(token).then((response) => {
      setCandidatos(response);
    });
  }, []);

  const PreguntasGetService = useCallback((data) => {
    setstate({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    PreguntasGet(data).then((response) => {
      let errores = response.errors;
      if (errores !== undefined) {
        let candidato = errores["candidato"];
        if (candidato != null) {
          setstate({
            loading: false,
            error: true,
            message: candidato[0],
            success: false,
          });
        }
      } else {
        setstate({
          loading: false,
          error: false,
          message: "Preguntas encontradas",
          success: true,
        });
        setPreguntasRespuestas(response);
      }
    });
  }, []);

  const logout = useCallback(
    (token) => {
      removeCookie("jwt");
      Logout(token).then((response) => {
        setCerrar(response);
      });
    },
    [removeCookie]
  );

  return {
    isLoggedIn: Auth.decode(cookies.jwt),
    user: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).username : "",
    user_id: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).user_id : "",
    use_email: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).email : "",
    rol: Auth.decode(cookies.jwt) ? Auth.decode(cookies.jwt).rol : "",
    user_status: Auth.decode(cookies.jwt)
      ? Auth.decode(cookies.jwt).status
      : "",
    token: Auth.decode(cookies.jwt)
      ? Auth.decode(cookies.jwt).token_type +
        " " +
        Auth.decode(cookies.jwt).access_token
      : undefined,
    loading: state.loading,
    error: state.error,
    message: state.message,
    Candidatos,
    PreguntasRespuestas,
    login,
    logout,
    CandidatosGetService,
    PreguntasGetService,
  };
}
