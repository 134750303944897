import { useCallback, useState } from 'react';
import { PreguntasGet, PreguntasCandidatoGet, PreguntasAdd, PreguntasUpdate, PreguntasDelete } from '../service/preguntas';

export default function usePreguntas() {
    const [Preguntas, setPreguntas] = useState([]);
    const [CandidatosPreguntas, setCandidatosPreguntas] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const PreguntasGetService = useCallback((token) => {
        PreguntasGet(token).then(response => {
            setPreguntas(response)
        })
    }, []);

    const PreguntasCandidatoGetService = useCallback((token) => {
        PreguntasCandidatoGet(token).then(response => {
            setCandidatosPreguntas(response)
        })
    }, []);
    /*
     * Agregar Preguntas
     */
    const PreguntasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PreguntasAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let candidato_id = errores['candidato_id'];
                let tipo = errores['tipo'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (candidato_id != null) {
                    setState({ loading: false, error: true, message: candidato_id[0], success: false })
                }
                if (tipo != null) {
                    setState({ loading: false, error: true, message: tipo[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Preguntas registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Preguntas',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Preguntas
     */
    const PreguntasUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PreguntasUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let candidato_id = errores['candidato_id'];
                let tipo = errores['tipo'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (candidato_id != null) {
                    setState({ loading: false, error: true, message: candidato_id[0], success: false })
                }
                if (tipo != null) {
                    setState({ loading: false, error: true, message: tipo[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Preguntas actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Preguntas',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Preguntas
     */
    const PreguntasDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PreguntasDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Preguntas eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Preguntas',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Preguntas,
        CandidatosPreguntas,
        PreguntasGetService,
        PreguntasCandidatoGetService,
        PreguntasAddService,
        PreguntasUpdateService,
        PreguntasDeleteService
    }



}