const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function PreguntasGet(token) {
    return fetch(`${ENDPOINT}preguntaGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PreguntasCandidatoGet(token) {
    return fetch(`${ENDPOINT}pregunta_candidato_Get`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PreguntasAdd(data, token) {
    return fetch(`${ENDPOINT}preguntaAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PreguntasUpdate(data, token) {
    return fetch(`${ENDPOINT}preguntaUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PreguntasDelete(data, token) {
    return fetch(`${ENDPOINT}preguntaDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}
