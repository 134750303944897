const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOPLOGIN;
const ENDPOINTP = process.env.REACT_APP_API_URL_DEVELOP;

export async function Login(data) {
    return fetch(`${ENDPOINT}login`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function Logout(token) {
    return fetch(`${ENDPOINTP}logout`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function CandidatosGet(data) {
    return fetch(`${ENDPOINT}candidato_Get`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PreguntasGet(data) {
    return fetch(`${ENDPOINT}preguntas_Get`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}
