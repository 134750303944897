import { useCallback, useState } from 'react';
import { EncuestasGet, ResultadosGet } from '../service/dashboard';

export default function useCandidato() {
    const [Encuestas, setEncuestas] = useState([]);
    const [Resultados, setResultados] = useState([]);
    const [SumaResultados, setSumaResultados] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const EncuestasGetService = useCallback((token) => {
        EncuestasGet(token).then(response => {
            setEncuestas(response.encuesta)
        })
    }, []);

    const ResultadosGetService = useCallback((token, id) => {
        ResultadosGet(token, id).then(response => {
            setResultados(response.respuestas);
            setSumaResultados(response.suma);
        })
    }, []);

    
    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Encuestas,
        Resultados,
        SumaResultados,
        EncuestasGetService,
        ResultadosGetService
    }
}