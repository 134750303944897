import { useCallback, useState } from 'react';
import { RespuestasGet, RespuestasPreguntaGet, RespuestasAdd, RespuestasUpdate, RespuestasDelete } from '../service/respuestas';

export default function useRespuestas() {
    const [Respuestas, setRespuestas] = useState([]);
    const [RespuestasRespuestas, setRespuestasRespuestas] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const RespuestasGetService = useCallback((token) => {
        RespuestasGet(token).then(response => {
            setRespuestas(response)
        })
    }, []);

    const RespuestasPreguntaGetService = useCallback((token) => {
        RespuestasPreguntaGet(token).then(response => {
            setRespuestasRespuestas(response)
        })
    }, []);
    /*
     * Agregar Respuestas
     */
    const RespuestasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        RespuestasAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let preguntas_id = errores['preguntas_id'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (preguntas_id != null) {
                    setState({ loading: false, error: true, message: preguntas_id[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Respuestas registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Respuestas',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Respuestas
     */
    const RespuestasUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        RespuestasUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let preguntas_id = errores['preguntas_id'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (preguntas_id != null) {
                    setState({ loading: false, error: true, message: preguntas_id[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Respuestas actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Respuestas',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Respuestas
     */
    const RespuestasDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        RespuestasDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Respuestas eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Respuestas',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Respuestas,
        RespuestasRespuestas,
        RespuestasGetService,
        RespuestasPreguntaGetService,
        RespuestasAddService,
        RespuestasUpdateService,
        RespuestasDeleteService
    }



}