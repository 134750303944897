const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function RespuestasGet(token) {
    return fetch(`${ENDPOINT}respuestaGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function RespuestasPreguntaGet(token) {
    return fetch(`${ENDPOINT}respuesta_pregunta_Get`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function RespuestasAdd(data, token) {
    return fetch(`${ENDPOINT}respuestaAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function RespuestasUpdate(data, token) {
    return fetch(`${ENDPOINT}respuestaUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function RespuestasDelete(data, token) {
    return fetch(`${ENDPOINT}respuestaDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}
