const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function EncuestasGet(token) {
    return fetch(`${ENDPOINT}dashboardRespuestas`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function ResultadosGet(token, id) {
    return fetch(`${ENDPOINT}resutados/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}