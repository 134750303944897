import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

export default function ModalsDelete({
    modalDelete,
    id,
    token,
    error,
    message,
    success,
    PreguntasGetService,
    PreguntasDeleteService,
    setModalDelete
}) {
  const cancelButtonRef = useRef(null);

  const Eliminar = (e) => {
    const data = new FormData();
    data.append("id", e);
    PreguntasDeleteService(data, token);

    Swal.fire({
      html: "Espere por favor...",
      timer: 2000,
      confirmButtonColor: "#3b82f6",
      timerProgressBar: true,
    });
  };

  useEffect(() => {
    if (success === true) {
      PreguntasGetService(token);
    }
  }, [PreguntasGetService, success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setModalDelete(false);
    }
  }, [message, success]);

  return (
    <>
      <Transition.Root show={modalDelete} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setModalDelete}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Eliminar cuenta
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        ¿Está seguro de que desea desactivar su cuenta? Todo
                          de sus datos serán eliminados permanentemente de nuestro
                          servidores para siempre. Esta acción no se puede deshacer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => Eliminar(id)}
                    >
                      Eliminar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setModalDelete(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
