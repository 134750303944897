import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Recuperar from "./pages/recuperar";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Candidatos from "./pages/candidatos";
import Encuestas from "./pages/encuestas";
import Cuestionario from "./pages/cuestionario";
import Preguntas from "./pages/preguntas";
import Respuestas from "./pages/respuestas";
import Resultados from "./pages/resultados";
import ResultadosInfo from "./pages/resultados/informacion";
import Usuarios from "./pages/usuarios";
import { UserContextProvider } from "./context/userContext";
import PrivateRoute from "./route/PrivateRoute";
import Loader from "./components/Loader";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/termometro/:id" element={<Cuestionario />} />
        <Route path="/recuperar-contrasena" element={<Recuperar />} /> */}
        <Route path="/" element={<PrivateRoute />}>

          <Route
            path="inicio"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="encuestas"
            element={
              <Suspense fallback={<Loader />}>
                <Encuestas />
              </Suspense>
            }
          />
          <Route
            path="preguntas"
            element={
              <Suspense fallback={<Loader />}>
                <Preguntas />
              </Suspense>
            }
          />
           <Route
            path="respuestas"
            element={
              <Suspense fallback={<Loader />}>
                <Respuestas />
              </Suspense>
            }
          />
          <Route
            path="resultados"
            element={
              <Suspense fallback={<Loader />}>
                <Resultados />
              </Suspense>
            }
          />
          <Route
            path="resultados/:id"
            element={
              <Suspense fallback={<Loader />}>
                <ResultadosInfo />
              </Suspense>
            }
          />
          <Route
            path="usuarios"
            element={
              <Suspense fallback={<Loader />}>
                <Usuarios />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
