import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState, Fragment, useEffect } from "react";
import usePreguntas from "../../../hooks/usePreguntas";
import Select from "react-select";
import Swal from "sweetalert2";

const TipoPregunta = [
  { value: "abierta", label: "abierta" },
  { value: "multiple", label: "multiple" },
];

export default function ModalsAdd({
  modal,
  setModal,
  token,
  id,
  nombre,
  setNombre,
  candidato,
  setCandidato,
  tipo,
  setTipo,
  update,
  PreguntasGetService,
}) {
  const {
    error,
    message,
    success,
    PreguntasAddService,
    PreguntasUpdateService,
    PreguntasCandidatoGetService,
    CandidatosPreguntas,
  } = usePreguntas();
  const cancelButtonRef = useRef(null);
  const [SelectedCandidato, setSelectedCandidato] = useState([]);
  const [SelectedTipo, setSelectedTipo] = useState([]);

  useEffect(() => {
    if (success === true) {
      PreguntasGetService(token);
    }
  }, [PreguntasGetService, success]);

  const Agregarpregunta = () => {
    if (update) {
      const data = new FormData();
      data.append("id", id);
      data.append("nombre", nombre);
      data.append("encuestas_id", candidato);
      data.append("tipo", tipo);

      PreguntasUpdateService(data, token);

      Swal.fire({
        title: "Actualizando pregunta",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      const data = new FormData();
      data.append("nombre", nombre);
      data.append("encuestas_id", candidato);
      data.append("tipo", tipo);

      PreguntasAddService(data, token);

      Swal.fire({
        title: "Registrando pregunta",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
      PreguntasCandidatoGetService(token);
  }, [PreguntasCandidatoGetService, success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setNombre("");
      setCandidato("");
      setTipo("");
      setModal(false);
    }
  }, [message, success]);

  return (
    <>
      <Transition.Root show={modal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      {update ? "Actualizar pregunta" : "Agregar pregunta"}
                    </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nombre
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Encuesta
                        </label>
                        <div className="mt-2">
                        <Select
                            value={SelectedCandidato}
                            onChange={(e) => {
                              setCandidato(e.value);
                              setSelectedCandidato(e);
                            }}
                            options={CandidatosPreguntas}
                            placeholder={
                              candidato
                                ? candidato
                                : "Seleccione un candidato"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 mb-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Tipo
                        </label>
                        <div className="mt-2">
                        <Select
                            value={SelectedTipo}
                            onChange={(e) => {
                              setTipo(e.value);
                              setSelectedTipo(e);
                            }}
                            options={TipoPregunta}
                            placeholder={
                              tipo
                                ? tipo
                                : "Seleccione tipo de pregunta"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => Agregarpregunta()}
                    >
                      {update ? "Actualizar" : "Agregar"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
