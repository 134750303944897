import { useCallback, useState } from 'react';
import { EncuestasGet, EncuestasAdd, EncuestasUpdate, EncuestasDelete } from '../service/encuestas';

export default function useEncuestas() {
    const [Encuestas, setEncuestas] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const EncuestasGetService = useCallback((token) => {
        EncuestasGet(token).then(response => {
            setEncuestas(response)
        })
    }, []);
    /*
     * Agregar Candidato
     */
    const EncuestasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        EncuestasAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let url = errores['url'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (url != null) {
                    setState({ loading: false, error: true, message: url[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Encuesta registrada con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Encuesta',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Candidato
     */
    const EncuestasUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        EncuestasUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let url = errores['url'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (url != null) {
                    setState({ loading: false, error: true, message: url[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Encuesta actualizada con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Encuesta',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Candidato
     */
    const EncuestasDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        EncuestasDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Encuesta eliminada con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Encuesta',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Encuestas,
        EncuestasGetService,
        EncuestasAddService,
        EncuestasUpdateService,
        EncuestasDeleteService
    }



}