import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import useLogin from "../../hooks/useLogin";
import useDashboard from "../../hooks/useDashboard";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Usuarios() {
  const { Encuestas, EncuestasGetService } = useDashboard();
  const { token } = useLogin();

  useEffect(() => {
    EncuestasGetService(token);
  }, [EncuestasGetService]);

  return (
    <div>
      <Menu>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Resultados de termometros
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Una lista de todas las encuestas, incluido su nombre, total de
                respuestas.
              </p>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Encuesta
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Total
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        URL
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Ver
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Encuestas.map((person, personIdx) => (
                      <tr key={person.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.nombre}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.total}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.url}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pr-4 pl-3 text-left text-sm font-medium sm:pr-8 lg:pr-8">
                          <Link
                            to={`/resultados/${person.id}`}
                            className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
                            style={{ cursor: "pointer" }}
                          >
                            Ver Resultados
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Menu>
    </div>
  );
}
