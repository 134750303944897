import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import useLogin from "../../hooks/useLogin";
import usePreguntas from "../../hooks/usePreguntas";
import ModalAdd from "./Modals/ModalsAdd";
import ModalsDelete from "./Modals/ModalsDelete";
import Swal from "sweetalert2";

export default function Preguntas() {
  const {
    loading,
    error,
    message,
    success,
    Preguntas,
    PreguntasGetService,
    PreguntasDeleteService
  } = usePreguntas();
  const { token } = useLogin();
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [id, setId] = useState("");
  const [update, setUpdate] = useState(false);
  const [nombre, setNombre] = useState("");
  const [candidato, setCandidato] = useState("");
  const [tipo, setTipo] = useState("");

  useEffect(() => {
    PreguntasGetService(token);
  }, [PreguntasGetService]);

  

  return (
    <div>
      <Menu>
        <ModalAdd 
        modal={modal} 
        token={token}
        id={id}
        setModal={setModal}
        nombre={nombre}
        setNombre={setNombre}
        candidato={candidato}
        setCandidato={setCandidato}
        tipo={tipo}
        setTipo={setTipo}
        update={update}
        PreguntasGetService = {PreguntasGetService}
         />
        <ModalsDelete
          modalDelete={modalDelete}
          id={id}
          token={token}
          error={error}
          message={message}
          success={success}
          PreguntasGetService={PreguntasGetService}
          PreguntasDeleteService={PreguntasDeleteService}
          setModalDelete={setModalDelete}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Preguntas
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Una lista de todas las preguntas de los candidatos
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  setModal(true);
                }}
              >
                Agregar pregunta
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Encuesta
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Tipo
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Editar
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Eliminar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Preguntas.map((person, personIdx) => (
                      <tr key={person.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.nombre}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.nom_encuesta}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {person.tipo}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                          <span
                            className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModal(true);
                              setId(person.id);
                              setNombre(person.nombre);
                              setCandidato(person.encuestas_id);
                              setTipo(person.tipo);
                              setUpdate(true);
                            }}
                          >
                            Editar
                          </span>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                          <span
                            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setId(person.id);
                              setModalDelete(true);
                            }}
                          >
                            Eliminar
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Menu>
    </div>
  );
}
